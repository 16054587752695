import React, { useState } from 'react';
import { HardwareTypeI, HardwareVerifyCollectionI } from '../../interfaces/hardware';
import SecondaryButton from '../buttons/SecondaryButton';
import Divider from '../Divider';
import { Form, Input, InputNumber, Spin, TimePicker, message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { DateFormat, TimeFormat, convertDayToISOString, convertToApiFile } from '../../utils/global';
import UploadAttachmentButton from '../buttons/UploadAttachmentButton';
import ConfirmButton from '../buttons/ConfirmButton';
import './ReviewStep.scss';
import { FillingParamsI } from '../../interfaces/hardwareFilling';
import { doFillingHardware } from '../../apis/hardwardFilling';
import FillingSuccessModal from './SuccessModal';
import { AttachmentCategories } from '../../constants';
import NonClosableModal from '../NonClosableModal';
import DetailsView from '../DetailsView';
import { handleGetUniqType } from '../../utils/hardware';
import Text from '../Text';
import Icon from '../Icon';
import SizeBox from '../SizeBox';
import EAnalytics from '../../analytics';
import dayjs from 'dayjs';

const ConfirmContent = () => {
  return (
    <div>
      <h2>Confirmation with Filling Report?</h2>
      <p>All the valid ecoTOTEs will be logged into your Hardware Tracking List as Filled.</p>
    </div>
  );
};

type Props = {
  verifiedData?: HardwareVerifyCollectionI;
  scannedData?: FillingParamsI;
  successData: any;
  setCurrentStep: (step: number) => void;
  setScannedData: (_: any) => void;
  setChosenType: (type: HardwareTypeI) => void;
  setSuccessData: (_: any) => void;
  setFinalScannedData: (_: { serialCode: string }[]) => void;
  setSiteId: (_: number) => void;
};

const FillingReviewStep: React.FC<Props> = ({
  successData,
  verifiedData,
  scannedData,
  setCurrentStep,
  setScannedData,
  setChosenType,
  setSuccessData,
  setFinalScannedData,
  setSiteId,
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);

  const handleModalCancel = () => {
    setIsSuccessModal(false);
    form.resetFields();
  };

  const { mutate: doCreateMutate, isLoading: isCreateLoading } = useMutation(doFillingHardware, {
    onSuccess: async (data) => {
      setSuccessData(data);
      queryClient.invalidateQueries();
      setIsSuccessModal(true);
      setScannedData(undefined);
      setFinalScannedData([]);
    },
    onError: () => {
      message.error('Your action was failed! Please try again');
    },
  });

  const handleSubmit = (values: FillingParamsI) => {
    console.log('values', values);
    const newValues: any = { ...values };
    const hardwareList = verifiedData?.hardwareList?.map((item: any) => {
      delete item.isInDB;
      delete item.error;
      delete item.currentData;
      delete item.type;
      return item;
    });
    newValues.hardwareList = hardwareList;
    newValues.plantId = scannedData?.plantId;
    newValues.productId = scannedData?.productId;
    newValues.lotNumber = scannedData?.lotNumber;
    newValues.barrelVolume = values?.barrelVolume?.toString() ?? null;
    newValues.primingVolume = values?.primingVolume?.toString() ?? null;
    newValues.fillingStartTime = convertDayToISOString(dayjs(values?.fillingStartTime));
    newValues.fillingEndTime = convertDayToISOString(dayjs(values?.fillingEndTime));
    setSiteId(scannedData?.plant?.site?.id ?? 0);

    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    doCreateMutate(newValues);
    EAnalytics.trackScanComplete('fill');
  };

  return (
    <Spin spinning={!verifiedData || isCreateLoading}>
      <div className="component-hardware-filling-review-step">
        <Form
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
        >
          <div className="content-wrap details-container" style={{ marginTop: 16 }}>
            <h2 className="details-title">Filling Details</h2>
            <div className="details-content-wrap">
              <div className="flex-row">
                <div className="item">
                  <div className="label">Created Data</div>
                  <div className="value">{DateFormat(new Date())}</div>
                </div>
                <div className="item">
                  <div className="label">Created Time</div>
                  <div className="value">{TimeFormat(new Date())}</div>
                </div>
              </div>
              <div className="form-container">
                <div className="form-content">
                  <div className="flex-row">
                    <Form.Item label="Filling Start Time" name="fillingStartTime">
                      <TimePicker format={'HH:mm:ss'} />
                    </Form.Item>
                    <Form.Item label="Filling End Time" name="fillingEndTime">
                      <TimePicker format={'HH:mm:ss'} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="item">
                  <div className="label">Product SKU</div>
                  <div className="value">{scannedData?.product?.sku}</div>
                </div>
                <div className="item">
                  <div className="label">Product Name</div>
                  <div className="value">{scannedData?.product?.name}</div>
                </div>
              </div>
              <Divider />
              <div className="item">
                <div className="label">Lot Number</div>
                <div className="value">{scannedData?.lotNumber}</div>
              </div>
              <div className="flex-row">
                <div className="item">
                  <div className="label">Product SKU</div>
                  <div className="value">{scannedData?.product?.sku}</div>
                </div>
                <div className="item">
                  <div className="label">Product Name</div>
                  <div className="value">{scannedData?.product?.name}</div>
                </div>
              </div>
              <Divider />
              <div className="form-container">
                <div className="form-content">
                  <div className="flex-row">
                    <Form.Item label="Total Incoming Liquid (L)" name="barrelVolume">
                      <InputNumber placeholder="0.0" />
                    </Form.Item>
                    <Form.Item label="Unused Partial Litres (L)" name="primingVolume">
                      <InputNumber placeholder="0.0" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="flex-row">
                <div className="item">
                  <div className="label">Number of ecoTOTEs</div>
                  <div className="value">{verifiedData?.numberOfValid}</div>
                </div>
              </div>
              <SizeBox height={12} />
              <DetailsView.Field
                label="Hardware Type"
                value={handleGetUniqType(verifiedData?.hardwareList ?? []).map(
                  (item: { type: string; count: number }) => (
                    <>
                      {item.type.includes('ecoTOTE') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                      {item.type.includes('ecoPLANT') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                      {item.type.includes('SmartPour') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                    </>
                  ),
                )}
              />
              <Divider />
              <div className="form-container">
                <div className="form-content">
                  <div className="item">
                    <div className="label">Site</div>
                    <div className="value">{scannedData?.plant?.site.legalName}</div>
                  </div>
                  <Form.Item label="Remarks" name="remarks">
                    <Input.TextArea placeholder="Type remarks" rows={5} />
                  </Form.Item>
                  <p className="label">Media Attachments</p>
                  <UploadAttachmentButton
                    categories={AttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div className="hardware-footer-section">
          <div>
            <SecondaryButton label="Previous Step" onClick={() => setCurrentStep(5)} />
            <ConfirmButton
              type="info"
              label="Finish Scanning"
              onOk={() => form.submit()}
              content={<ConfirmContent />}
              closable={false}
            />
          </div>
        </div>
      </div>
      <NonClosableModal isVisible={isSuccessModal} handleCancel={handleModalCancel}>
        <FillingSuccessModal data={successData} setChosenType={setChosenType} setCurrentStep={setCurrentStep} />
      </NonClosableModal>
    </Spin>
  );
};

export default FillingReviewStep;
