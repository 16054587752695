import React from 'react';

type Props = {
  size?: number;
  color?: string;
};

const ProducGroupIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 22}
      height={size ?? 22}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 4062">
        <g id="Vector">
          <path
            d="M5.3335 8.66675V14.2223C5.3335 16.9723 7.1335 19.2501 9.50016 19.6945V23.4816H7.00016V25.3334H13.6668V23.4816H11.1668V19.6945C13.5335 19.2501 15.3335 16.9723 15.3335 14.2223V8.66675H5.3335ZM13.6668 13.2964H7.00016V10.5186H13.6668V13.2964Z"
            fill={color ?? 'currentColor'}
          />
          <path
            d="M18.6668 8.66675V14.2223C18.6668 16.9723 20.4668 19.2501 22.8335 19.6945V23.4816H20.3335V25.3334H27.0002V23.4816H24.5002V19.6945C26.8668 19.2501 28.6668 16.9723 28.6668 14.2223V8.66675H18.6668ZM27.0002 13.2964H20.3335V10.5186H27.0002V13.2964Z"
            fill={color ?? 'currentColor'}
          />
        </g>
        <path
          id="Vector_2"
          d="M8.66683 0.333374H2.8335C1.45016 0.333374 0.333496 1.45004 0.333496 2.83337V8.66671H3.66683V3.66671H8.66683V0.333374ZM25.3335 0.333374H31.1668C32.5502 0.333374 33.6668 1.45004 33.6668 2.83337V8.66671H30.3335V3.66671H25.3335V0.333374ZM8.66683 33.6667H2.8335C1.45016 33.6667 0.333496 32.55 0.333496 31.1667V25.3334H3.66683V30.3334H8.66683V33.6667ZM25.3335 33.6667H31.1668C32.5502 33.6667 33.6668 32.55 33.6668 31.1667V25.3334H30.3335V30.3334H25.3335V33.6667Z"
          fill={color ?? 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default ProducGroupIcon;
