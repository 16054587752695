import { Form, message, Modal, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { updateFillingHistories, useGetFillingHistory } from '../../apis/hardwardFilling';
import FillingHistoryInformation from '../../components/FillingHistory/Information';
import DetailsTopSection from '../../components/shared/DetailTopSection';
import { useMutation, useQueryClient } from 'react-query';
import AddAttachmentForm from '../../components/forms/AddAttachmentForm';
import { ProductAttachmentCategories } from '../../constants';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import FillingResources from '../../components/FillingHistory/Resource';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import HardwareChooseType from '../../components/HardwareFilling/HardwareChooseType';
import HardwareW2WGroupingPage from '../../components/shared/Hardware/W2WGrouping';
import { SiteI } from '../../interfaces/site';
import { HardwareTypeI } from '../../interfaces/hardware';

const FillingDetailsPage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data, isFetching } = useGetFillingHistory(uuid);
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'filling-histories');
  const [isGroupingModalVisible, setIsGroupingModalVisible] = useState<boolean>(false);
  const [isRenderCreateGrouping, setIsRenderCreateGrouping] = useState<boolean>(false);
  const [hardwareType, setHardwareType] = useState<HardwareTypeI>();
  const [site, setSite] = useState<SiteI>();

  const hardwareTypes = useMemo(() => {
    if (data && data.hardwareList && Object.keys(data.hardwareList).length > 0) {
      const list = Array.from(
        new Set(
          data.hardwareList.map((item) => {
            const hardware = item.currentData?.ecoTote;
            return `ecotote${hardware?.volume.toLowerCase()}`;
          }),
        ),
      ).map((value) => {
        const volume = value.replace('ecotote', '');
        return {
          label: `ecoTOTE ${volume.toLocaleUpperCase()}`,
          value: value,
        };
      });
      setHardwareType(list[0].value as HardwareTypeI);
      setSite(data.site);
      return list;
    }
  }, [data]) as { label: string; value: string }[];

  const { mutate } = useMutation(updateFillingHistories, {
    onSuccess: async () => {
      message.info('Uploaded attachment!');
      queryClient.invalidateQueries({
        queryKey: [`filling-histories/${uuid}`],
      });
    },
    onError: () => {
      message.error('Adding attachment failed! Please try again');
    },
  });

  function handleCancelUploadAttachment() {
    setIsVisibleModalUpload(false);
    form.resetFields(['categoryName', 'file']);
  }

  function handleCreateGroupingCancel() {
    setIsGroupingModalVisible(false);
  }

  const handleActionsClick = (key: string) => {
    if (key === 'create-grouping') {
      setIsGroupingModalVisible(true);
    }
  };

  const handleChooseTypeChange = (e: any) => {
    setHardwareType(e.target.value);
  };

  const onCreateGroupingConfirm = () => {
    setIsGroupingModalVisible(false);
    setIsRenderCreateGrouping(true);
  };

  const withActions = [
    {
      key: 'create-grouping',
      label: (
        <>
          <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
            <Icon name="icon-group_work" size={16} />
            <span>Create Grouping</span>
          </Text>
        </>
      ),
    },
  ];

  return (
    <Spin spinning={isFetching}>
      <div className="tw-p-4">
        {!isRenderCreateGrouping && (
          <>
            <DetailsTopSection
              label={`Fill Report - ${data?.uuid}`}
              setIsVisibleModalUpload={setIsVisibleModalUpload}
              access={access}
              withActions={withActions}
              handleActionsClick={handleActionsClick}
            />
            <FillingHistoryInformation data={data} />
            <FillingResources data={data} />
          </>
        )}
        <Modal
          open={isVisibleModalUpload}
          onOk={handleCancelUploadAttachment}
          onCancel={handleCancelUploadAttachment}
          footer={null}
          width={560}
        >
          <AddAttachmentForm
            form={form}
            handleCancel={handleCancelUploadAttachment}
            multiple
            categories={ProductAttachmentCategories}
            update={mutate}
          />
        </Modal>
        <Modal
          open={isGroupingModalVisible}
          onOk={handleCreateGroupingCancel}
          onCancel={handleCreateGroupingCancel}
          footer={null}
          width={560}
        >
          <HardwareChooseType
            type="ecotote"
            onChange={handleChooseTypeChange}
            onConfirmClick={onCreateGroupingConfirm}
            onBackClick={handleCreateGroupingCancel}
            hardwareTypes={hardwareTypes || []}
          />
        </Modal>
        {isRenderCreateGrouping && (
          <HardwareW2WGroupingPage
            site={site}
            siteId={site?.id}
            registerType={hardwareType}
            successData={data}
            backTo={`/filling-histories/${uuid}`}
          />
        )}
      </div>
    </Spin>
  );
};

export default FillingDetailsPage;
