import React from 'react';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import Text from '../Text';
import SuccessSubmittedItem from '../shared/SuccessSubmittedItem';
import Icon from '../Icon';

type Props = {
  data?: any;
};

const ProductGroupSuccessModal: React.FC<Props> = ({ data }) => {
  return (
    <div className="tw-text-center tw-py-4 tw-px-4">
      <div className="tw-flex tw-flex-col tw-items-center">
        <CheckCircleIcon width={48} height={48} color="#0A7A08" />
        <Text variant="h3" className="tw-mt-2 tw-mb-3">
          You have successfully created Product!
        </Text>
      </div>
      <div className="notice tw-mb-8 tw-text-grey-700">
        <Text variant="bodyMd" className="!tw-block">
          Additional steps are available for this entry.
        </Text>
        <Text variant="bodyMd" className="!tw-block">
          You can choose the options below
        </Text>
      </div>
      <div className="tw-flex tw-gap-3">
        <SuccessSubmittedItem
          url={`/products/groups/${data?.code}`}
          title="View the Product Group"
          description={['View the report of your group of Products']}
          layout="horizontal"
          icon={<Icon name="icon-library_books" size={40} className="tw-text-primary-800" />}
        />
        <SuccessSubmittedItem
          url={`/products`}
          title="Proceed to Manage Products"
          description={['View a list of available Products']}
          layout="horizontal"
          icon={<Icon name="icon-arrow_forward" size={40} className="tw-text-primary-800" />}
        />
      </div>
    </div>
  );
};

export default ProductGroupSuccessModal;
